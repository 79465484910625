<template>
  <form class="signup-form" :class="formType" @submit.prevent="submitForm">
    <div class="form-base-block" :class="{'s-scroll' : formType === 'payment'}">
      <section v-if="formType == 'default'" class="top-border"></section>

      <section class="form-base-innerblock" v-if="formType !== 'alpha'">
        <h1 v-if="formType == 'default' || formType == 'payment'">{{ $t('sign-up.started-title') }}</h1>
        <h4 @click="showLoginModal = !showLoginModal" v-if="formType == 'default' || formType == 'payment'" class="s-text-blue s-text-select-link">{{ $t('sign-up.log-in-title') }}</h4>
      </section>

      <!-- Form for Alpha -->
      <section class="form-base-innerblock" v-if="formType == 'alpha'">
        <span>{{ $t('sign-up.email-title') }}</span>
        <input type="email" name="email" class="form-input" placeholder="" maxlength="256" required="">

        <span v-if="formType == 'default' || formType == 'payment'" >{{ $t('sign-up.password-title') }}</span>
        <input v-if="formType == 'default' || formType == 'payment'" type="password" class="form-input" placeholder="" maxlength="256" name="" required="">

        <div class="select-options">
          <div id="signup-terms">
            <label >{{ $t('sign-up.notify-title') }}</label>

            <div class="radio-buttons">
              <label class="s-pointer">
                <input type="radio" name="newsletter" value="1" required=""> {{ $t('sign-up.yes-title') }}
              </label>
              <label class="s-pointer">
                <input type="radio" name="newsletter" value="0" required=""> {{ $t('sign-up.no-title') }}
              </label>
            </div>
            <!--            <small class="error">required</small>-->

            <label class="s-pointer">
              <input name="terms" type="checkbox" required="">{{ $t('sign-up.agree-title') }}<a onclick="window.parent.changeRoot('/dynamic/terms_of_use')" class="conditions-link">{{ $t('sign-up.terms-title') }}</a> {{ $t('sign-up.and-title') }} <a onclick="window.parent.changeRoot('/dynamic/privacy_policy')" class="conditions-link">{{ $t('sign-up.privacy-title') }}</a>
            </label>
            <!--              <small class="error">required</small>-->
          </div>
        </div>

        <input type="submit" class="s-button-link signup-btn button-primary-blue continue s-button-round" :value="formConfirm" />
      </section>

      <section class="signup-form-iframe" v-if="formType !== 'alpha'">
        <iframe class="" id="login-frame" :src="signupUrl" name="login-frame" data-login=""></iframe>
      </section>

      <!-- Obsolete & Backup -->
      <section v-if="formType == 'default' || formType == 'payment'" class="form-social s-hide">
        <s-button class="s-button-link signup-btn button-social google s-button-round">{{ $t('actions.sign-google') }}</s-button>
        <s-button class="s-button-link signup-btn button-social facebook s-button-round">{{ $t('actions.sign-facebook') }}</s-button>
      </section>
    </div>

  </form>

  <transition name="t-backdrop">
    <section class="s-modal-backdrop-container" v-if="showLoginModal">
      <s-modal
        modal-type="login"
        @click-leave="showLoginModal = !showLoginModal"
        @click-close="showLoginModal = !showLoginModal"
        @click-confirm="showLoginModal = !showLoginModal"
      ></s-modal>
    </section>
  </transition>
</template>
<script>

import SButton from "../components/SButton.vue";
import Swal from "sweetalert2";
import SModal from "../components/SModal.vue";

export default {
  name: 'SignUpForm',
  components: {
    SModal,
    SButton,
  },
  props: {
    inPayment: {
     type:  Boolean,
     default: false
    },
    formType: {
     type:  String,
     default: 'default'
    }
  },
  data() {
    return {
      showLoginModal: false
    }
  },

  computed: {
    formConfirm() {
      switch (this.formType) {
        case "default":
          return this.$t('actions.continue');
        case "alpha":
          return this.$t('actions.submit');
        default:
          return undefined;
      }
    },
    signupUrl() {
      if (import.meta.env.VITE_V2 == "1") {
        return 'https://v2.seedr.cc/api/v0.1/auth/pages/signup'
      } else {
        return 'https://www.seedr.cc/auth/pages/signup'
      }
    }
  },
  methods: {
    async submitForm(event) {
      // Directly construct form data from event.target
      let formData = new FormData(event.target);

      // Post data with fetch
      const response = await fetch('https://www.seedr.cc/v2/signup', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        Swal.fire(
          'Congratulations!',
          'You have been submitted to the alpha waitlist.',
          'success'
        )
      } else {
        Swal.fire(
          'Oops!',
          'Something went wrong.',
          'error'
        )
      }
    }
  }
}
</script>
